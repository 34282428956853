<template>
  <auth-layout>
    <template v-slot:title>
      <span>{{ $t("create") }}</span>
      <span>{{ $t("account") }}</span>
    </template>
    <template v-slot:subtitle>
      <span>{{ $t("auth_form_create_account_text") }}</span>
    </template>
    <template v-slot:form>
      <v-form
        ref="form"
        class="sign-up-form"
        lazy-validation
        @submit.prevent="userSignUp"
      >
        <!-- email -->
        <v-text-field
          class="form-input"
          v-model.trim="form.email"
          :label="$t('email')"
          :rules="[(val) => rules.email(val) || $t('auth_form_invalid_email')]"
          validate-on-blur
          @focus.stop="hideNotification"
          @focusout="checkUser"
          id="email"
          data-testid="email"
        ></v-text-field>
        <!-- first and last name -->
        <div class="user-name">
          <v-text-field
            class="form-input"
            v-model.trim="form.firstName"
            :label="$t('first_name')"
            :rules="[
              () => !!form.firstName || $t('auth_form_invalid_first_name'),
            ]"
            validate-on-blur
            id="first-name"
            data-testid="first-name"
          ></v-text-field>
          <v-text-field
            class="form-input"
            v-model.trim="form.lastName"
            :label="$t('last_name')"
            :rules="[
              () => !!form.lastName || $t('auth_form_invalid_last_name'),
            ]"
            validate-on-blur
            id="last-name"
            data-testid="last-name"
          ></v-text-field>
        </div>
        <!-- birth date | DO NOT DELETE!!! -->
        <!-- menu -->
        <template v-if="!useDevicePicker && showBirthDateInput">
          <v-menu
            v-model="dateMenu"
            transition="scale-transition"
            min-width="auto"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="form-input"
                :value="dateFormat"
                :label="$t('birth_date')"
                v-bind="attrs"
                v-on="on"
                readonly
                id="birth-date"
                data-testid="birth-date"
              >
                <template v-slot:append>
                  <span class="material-icons" @click.stop="dateMenu = true">
                    calendar_today
                  </span>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              @change="dateMenu = false"
              v-model="form.birthDate"
              :max="maxDate"
              :min="minDate"
              :active-picker="activePicker"
            />
          </v-menu>
        </template>
        <!-- native -->
        <template v-if="useDevicePicker && showBirthDateInput">
          <v-text-field
            class="form-input"
            v-model="form.birthDate"
            :label="$t('birth_date')"
            type="date"
            :max="maxDate"
            :min="minDate"
            :rules="dateValidator"
            validate-on-blur
            id="birth-date"
            data-testid="birth-date"
          >
            <template v-slot:append>
              <span class="material-icons"> calendar_today </span>
            </template></v-text-field
          >
        </template>
        <!-- password -->
        <div class="user-password">
          <v-text-field
            class="form-input"
            v-model="form.password"
            :label="$t('password')"
            :type="showPassword ? 'text' : 'password'"
            @focus.stop="onPasswordFocus"
            @focusout="onPasswordBlur"
            id="password"
            data-testid="password"
          >
            <template v-slot:append>
              <div class="password-icon" @click="showPassword = !showPassword">
                <span class="material-icons" v-if="showPassword">
                  visibility
                </span>
                <span class="material-icons" v-if="!showPassword">
                  visibility_off
                </span>
              </div>
            </template>
          </v-text-field>
          <div class="password-hint" v-if="showPasswordHint">
            <span class="material-icons" v-if="form.password.length >= 8">
              done
            </span>
            <span class="hint-text">{{ $t("auth_form_password_hint") }}</span>
          </div>
        </div>

        <form-terms descriptionText="auth_form_terms_line"/>

        <div class="form-buttons">
          <mem-button
            type="submit"
            btnType="secondary-dark"
            :disabled="disableBtn"
            :loading="loading"
          >
            {{ $t("sign_up_and_continue") }}
          </mem-button>
          <template>
            <span class="divider">{{ $t("or") }}</span>
            <apple-auth-button
              class="auth-button"
              :redirect-url="redirectUrl"
              :text="$t('continue_with_apple')"
            />
            <facebook-button
              class="auth-button"
              :redirect-url="redirectUrl"
              :text="$t('continue_with_facebook')"
            ></facebook-button>
            <google-sign-in-button
              v-if="!isWebView"
              class="auth-button"
              :redirect-url="redirectUrl"
              :text="$t('continue_with_google')"
            />
          </template>
        </div>
      </v-form>
    </template>
    <template v-slot:footer>
      <span>{{ $t("already_have_account") }}</span>
      <span class="text-link" @click="goToSignIn">{{ $t("sign_in") }}</span>
    </template>
  </auth-layout>
</template>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import { emailValidator } from "@/common/validators";
import deviceInfoMixin from "./deviceInfoMixin";
import mixinAuthForms from "./mixinAuthForms";
import GoogleSignInButton from "@/components/GoogleSignInButton";
export default {
  name: "FormSignUp",
  data: () => ({
    form: {
      email: "",
      firstName: "",
      lastName: "",
      // birthDate: "",
      password: "",
    },
    rules: {
      email: emailValidator,
    },
    showPassword: false,
    dateMenu: false,
    activePicker: null,
    showPasswordHint: false,
    loading: false,
    showBirthDateInput: false,
  }),
  mixins: [deviceInfoMixin, mixinAuthForms],
  components: {
    GoogleSignInButton,
    "auth-layout": () => import("./AuthLayout.vue"),
    "mem-button": () => import("../../components/base/BaseButton.vue"),
    "facebook-button": () => import("@/components/ButtonFacebook.vue"),
    "apple-auth-button": () => import("@/components/ButtonAppleAuth.vue"),
    "form-terms": () => import("./TermsSection.vue"),
  },
  computed: {
    minDate() {
      return "1940-01-01";
    },
    maxDate() {
      return moment().subtract(14, "years").format("Y-MM-DD");
    },
    dateValidator() {
      const rules = [(val) => !!val || this.$t("auth_form_invalid_date_value")];
      rules.push((val) => {
        if (val > this.maxDate)
          return this.$t("auth_form_invalid_date_of_birth");
        return true;
      });
      return rules;
    },
    dateFormat() {
      if (!this.form.birthDate) return "";
      return moment(this.form.birthDate).format("MMMM DD, YYYY");
    },
    disableBtn() {
      let { email, firstName, lastName, password } = this.form;
      return (
        !emailValidator(email) ||
        !(password.length >= 8) ||
        !(firstName.length > 0) ||
        !(lastName.length > 0)
        // !(birthDate.length > 0) ||
        // birthDate > this.maxDate
      );
    },
    useDevicePicker() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
  watch: {
    dateMenu(val) {
      val && !this.model && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    ...mapActions(["checkUserExist", "handleToolbarNotification", "signUp"]),
    goToSignIn() {
      this.hideNotification();
      this.$router.push({ name: "formSignIn", query: this.$route.query });
    },
    async checkUser() {
      if (!this.form.email) return;
      try {
        let user = await this.checkUserExist(this.form.email);
        if (user.exists && this.$route.name === "formSignUp")
          this.showExistNotification();
      } catch (err) {
        console.log("", err);
      }
    },
    async userSignUp() {
      let { email, firstName, lastName, password } = this.form;
      let { lang, country } = this.$route.query;
      this.loading = true;
      try {
        let result = await this.signUp({
          data: {
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName,
            // birth_date: birthDate,
            language: lang,
            spartan_country_code: country,
          },
          redirect: this.redirectUrl,
        });
        window.location = result;
        // console.log("result", result);
      } catch (e) {
        this.loading = false;
        if (e.multipass) this.$router.push("home");
        if (e.exists) this.showExistNotification();
        // console.log(e);
      }
    },
    onPasswordFocus() {
      this.showPasswordHint = true;
    },
    onPasswordBlur() {
      this.showPasswordHint = false;
    },
    showExistNotification() {
      this.handleToolbarNotification({
        isVisible: true,
        body: "auth",
        data: {
          email: this.form.email,
        },
      });
    },
    hideNotification() {
      this.handleToolbarNotification({ isVisible: false });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "formLogin") {
        vm.form.email = vm.$route.params?.email;
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.hideNotification();
    next();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
$icon-color: rgba(255, 255, 255, 0.4);
.user-name {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  @media screen and (max-width: 499px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
}
.form-input {
  .material-icons {
    color: $icon-color;
    margin-bottom: 6px;
    @include cursorPointer;
  }

  ::v-deep {
    .v-text-field__slot {
      z-index: 1;

      input {
        text-align: left;
        &::-webkit-date-and-time-value {
          text-align: left;
        }
      }
    }
  }
}
.user-password {
  display: flex;
  position: relative;
  .password-hint {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 20px;
    .hint-text {
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      opacity: 0.4;
      line-height: 14px;
    }
    .material-icons {
      color: #64dd17;
      font-size: 18px;
      margin-right: 7px;
      line-height: 14px;
    }
  }
}
.sign-up-form {
  .form-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    margin-bottom: 34px;
    @media screen and (max-width: 499px) {
      margin-top: 44px;
      margin-bottom: 44px;
    }
    .divider {
      margin-top: 26px;
      margin-bottom: 26px;
      text-transform: uppercase;
      text-align: center;
    }
    .auth-button {
      & + .auth-button {
        margin-top: 12px;
      }
    }
  }
}

::v-deep {
  .form-footer {
    text-align: center;
  }
}
</style>
